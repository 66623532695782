body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ml-auto{
  margin-left: auto;
}
.homeBlogSection{
  /* background-color: lightgray; */
}
.breadcrumb-home a{
  color: #0ABAB5 !important;
  text-decoration: none;
}
.breadcrumb-home a:hover{
  color: #d53a9d !important;
  text-decoration: underline;
}
.btn-primary {
  background: #0ABAB5 !important;
  color: #fff;
  border: 2px solid #0ABAB5 !important;
}
#colorlib-footer {
  background: #212125;
  text-align: left;
}
#colorlib-footer .colorlib-footer-links {
  padding: 0;
  margin: 0;
}
#colorlib-footer .colorlib-footer-links li {
  padding: 0;
  margin: 0 0 5px 0;
  list-style: none;
  display: block;
}
#colorlib-footer .colorlib-footer-links li a {
  color: white;
  text-decoration: none;
}
#colorlib-footer .colorlib-footer-links li a i {
  padding-right: 5px;
  color: #35c9bf;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa fa-check:before {
  content: "\e116";
}
#colorlib-footer .colorlib-footer-links li a:hover {
  color: #0ABAB5;
}

a:hover, a:active, a:focus {
  color: #0ABAB5;
  outline: none;
  text-decoration: none;
}
/* .container:before, .container:after {
  content: " ";
  display: table;
}
.row:before, .row:after {
  content: " ";
  display: table;
} */
a:hover, a:active, a:focus {
  color: #0ABAB5;
  outline: none;
  text-decoration: none;
}
footer{
  padding: 60px;
  padding-bottom: 0px;
}
footer h2{
  color: #fff;
}
footer a{
  outline: none;
  text-decoration: none;
}
#colorlib-footer .tags span a:hover {
  background: #0ABAB5;
  color: #fff;
  border: 1px solid #0ABAB5;
  -webkit-box-shadow: 0 2px 10px -5px black;
  box-shadow: 0 2px 10px -5px black;
}

#colorlib-footer .tags span {
  float: left;
  margin-bottom: 3px;
  margin-right: 3px;
}
#colorlib-footer .tags span a {
  border: 1px solid #333333;
  color: white;
  padding: 5px 12px;
  font-size: 13px;
  border-radius: 4px;
}
#colorlib-footer .tags span a .fa{
  color: #0ABAB5;
}
#colorlib-footer .tags span a:hover .fa{
  color: #fff;
}
#carouselExampleIndicators img{
  height: 600px;
  object-fit:cover;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff !important;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }
  
  blockquote{
    background: lightblue;
    padding: 20px;
    font-style: italic;
  }
  .headingBorder{
    content: '';
    border-bottom: 3px solid #000;
    display: block;
    margin: auto;
    width: 45%;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #021a3c, #41f1dd);
  }
  .contact-info-wrap .fa{
    font-size: 20px !important;
    margin-right: 7px;
  }
.btn-primary:hover{
  color: #163a6d !important;
}
.btn-primary:focus{
  color: #163a6d;
}
.ml-5{
  margin-left: 50px;
}
.navbar-brand a{
  color: #0ABAB5 !important;
  text-decoration: none;
  font-weight: bold;
}
.text-pnt{
  color: #0ABAB5;
}
.w-40{
  width: 25% !important;
}
.ml-0{
  margin-left: 0px !important;
}
.float-right{
  float: right !important;
}
.home-slider{
  /* height: 250px; */
  object-fit: contain;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.home-slider-div{
  margin-right: 10px !important;
}
.homeQuoteSection .react-multi-carousel-list{
  height: 250px;
}
.homeQuoteSection .react-multi-carousel-track {
  height: 250px;
}
.mr-3{
  margin-right: 30px !important;
}
.cardImg{
  object-fit: cover;
  height: 250px;
}
.bloglink{
  outline: none;
  text-decoration: none;
}
.bloglink:hover .card-title{
  color: #0ABAB5;
}
#colorlib-footer{

  background: black;
}
.footerBorder{
  padding: 50px 70px !important;
  border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(45deg, #d53a9d 0 15%,black 15% 85%, #743ad5 0% 100%);
}
.footerContact i{
  color: #0ABAB5;
}
.footerContactIcon{
  margin-left: 20px;
  font-size: 30px;
}